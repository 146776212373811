import React from "react";

export const Contact = (props) => {
  return (
    <div>
      <div id="company3">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Entre em Contato Conosco</h2>
                <p>
                  Estamos para atender nossos clientes, entender as necessidades e propor soluções para atingir melhores resultados.
                </p>
              </div>
            </div>
          </div>
          {/* Informações de Contato */}
          <div className="contact-info">
            <h2>Informações de Contato</h2>
            <ul>
              <li>
                <i className="fas fa-map-marker-alt"></i> São José dos Campos, São Paulo
              </li>
              <li>
                <i className="fas fa-phone"></i> <i className="fab fa-whatsapp" style={{ marginRight: '5px' }}></i>+55 (12) 99111-4666 | +55 (12) 98260-0308
              </li>
              <li>
                <i className="far fa-envelope"></i> contato@aeromaxdrones.com.br
              </li>

              <li>
                <i className="far fa-instragram"></i> https://www.instagram.com/aeromax_drones/
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id="company3">
        <div className="container text-center">
          <p>
            &copy; 2023 Aeromax Drones. Todos os direitos reservados. Desenvolvido por Aeromax Drones.
          </p>
        </div>
      </div>
    </div>
  );
};
